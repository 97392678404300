/* eslint-disable consistent-return */
import { useEffect } from 'react';
import debounce from 'lodash/debounce';

const useWindowWidth = (props) => {
  const {
    setWindowWidth,
  } = props;

  useEffect(() => {
    if (typeof window === 'undefined') return;
    setWindowWidth(window.innerWidth);
    const handleResize = () => setWindowWidth(window.innerWidth);
    const debouncedUpdateWidth = debounce(handleResize, 200);
    window.addEventListener('resize', debouncedUpdateWidth);
    return () => {
      window.removeEventListener('resize', debouncedUpdateWidth);
    };
  }, [setWindowWidth]);
};

export default useWindowWidth;

import React, { useState, useEffect } from 'react';
import { IoIosArrowUp } from 'react-icons/io';
import styles from './scrolltotop.module.scss';

const ScrollTopArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > window.innerHeight / 2) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= window.innerHeight / 2) {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return function cleanup() {
      window.removeEventListener('scroll', checkScrollTop);
    };
  });

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <IoIosArrowUp className={styles.scrollTop} onClick={scrollTop} style={{ height: 50, display: showScroll ? 'flex' : 'none' }} />
  );
};

export default ScrollTopArrow;
